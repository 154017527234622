// extracted by mini-css-extract-plugin
export const box = "SearchNewcarHorizontalArrangement-module--box--2NV3N";
export const newcarSearchArea = "SearchNewcarHorizontalArrangement-module--newcar-search-area--226Y-";
export const spSearchHeadingPosition = "SearchNewcarHorizontalArrangement-module--sp-search-heading-position--1ES-f";
export const semicircle = "SearchNewcarHorizontalArrangement-module--semicircle--1rty4";
export const newcarSearchAreaBg = "SearchNewcarHorizontalArrangement-module--newcar-search-area-bg--3PSFL";
export const newcarHeading = "SearchNewcarHorizontalArrangement-module--newcar-heading--1Ditp";
export const isMarginCarsearch = "SearchNewcarHorizontalArrangement-module--is-margin-carsearch--3a6Ke";
export const topSearch = "SearchNewcarHorizontalArrangement-module--top-search--_NG0J";
export const topSearchFeeRangeWrapper = "SearchNewcarHorizontalArrangement-module--top-search-fee-range-wrapper--19xd0";
export const topSearchFeeRangeTitle = "SearchNewcarHorizontalArrangement-module--top-search-fee-range-title--2eVlz";
export const isLarge = "SearchNewcarHorizontalArrangement-module--is-large--2ghAT";
export const topSearchFeeRangeContainer = "SearchNewcarHorizontalArrangement-module--top-search-fee-range-container--CNrnt";
export const topSearchFeeRangeButton = "SearchNewcarHorizontalArrangement-module--top-search-fee-range-button--39kTv";
export const topSearchFeeRangeLink = "SearchNewcarHorizontalArrangement-module--top-search-fee-range-link--iCKTF";
export const top_search_button = "SearchNewcarHorizontalArrangement-module--top_search_button--315Yj";
export const searchText = "SearchNewcarHorizontalArrangement-module--search-text--4B1kW";
export const modal_maker_width = "SearchNewcarHorizontalArrangement-module--modal_maker_width--2TMH0";
export const modal_maker_nostyle = "SearchNewcarHorizontalArrangement-module--modal_maker_nostyle--1Hb3k";
export const modal_maker_input = "SearchNewcarHorizontalArrangement-module--modal_maker_input--9T4Vz";
export const modal_maker_items = "SearchNewcarHorizontalArrangement-module--modal_maker_items--1pcF1";
export const modal_body_type_label = "SearchNewcarHorizontalArrangement-module--modal_body_type_label---jYEE";
export const modal_body_type_one_text = "SearchNewcarHorizontalArrangement-module--modal_body_type_one_text--3d2vR";
export const modal_body_type_two_text = "SearchNewcarHorizontalArrangement-module--modal_body_type_two_text--2QnWy";
export const modal_maker_icons = "SearchNewcarHorizontalArrangement-module--modal_maker_icons--2Xk1N";
export const boxAdjust = "SearchNewcarHorizontalArrangement-module--box-adjust--1eoXD";
export const top_search_button_ab = "SearchNewcarHorizontalArrangement-module--top_search_button_ab--2p3EP";
export const topLinkIconArrow = "SearchNewcarHorizontalArrangement-module--top-link-icon-arrow--GzTJN";
export const top_small_size = "SearchNewcarHorizontalArrangement-module--top_small_size--2npfL";
export const selectionButton = "SearchNewcarHorizontalArrangement-module--selection-button--q0fhp";
export const selectionButtonText = "SearchNewcarHorizontalArrangement-module--selection-button-text--2Obut";
export const isTabsFirst = "SearchNewcarHorizontalArrangement-module--is-tabs-first--3yi3Q";
export const isTabsSecond = "SearchNewcarHorizontalArrangement-module--is-tabs-second--oobay";
export const searchTabsLabel = "SearchNewcarHorizontalArrangement-module--search-tabs-label--1Gve-";
export const tabElement01 = "SearchNewcarHorizontalArrangement-module--tab-element01--2RCj5";
export const tabElement02 = "SearchNewcarHorizontalArrangement-module--tab-element02--1yRV5";
export const searchHeader = "SearchNewcarHorizontalArrangement-module--search-header--3tbpw";
export const searchThumbnail = "SearchNewcarHorizontalArrangement-module--search-thumbnail--2O-jE";
export const isNoneFlexGrow = "SearchNewcarHorizontalArrangement-module--is-none-flex-grow--l-goT";
export const searchComment = "SearchNewcarHorizontalArrangement-module--search-comment--2hqZ7";
export const searchTabsWrapper = "SearchNewcarHorizontalArrangement-module--search-tabs-wrapper--2Q-1u";
export const headingContainer = "SearchNewcarHorizontalArrangement-module--heading-container--1rIYe";
export const searchCardContainer = "SearchNewcarHorizontalArrangement-module--search-card-container--2t6ym";
export const priceBox = "SearchNewcarHorizontalArrangement-module--priceBox--1CkBQ";
export const devideLine = "SearchNewcarHorizontalArrangement-module--devideLine--JY3hI";
export const top_search_button_usedcar = "SearchNewcarHorizontalArrangement-module--top_search_button_usedcar--2tk0n";
export const abUsedcarFlowP4 = "SearchNewcarHorizontalArrangement-module--ab-usedcar-flow-p4--2ytuC";
export const abUsedcarFlowP5 = "SearchNewcarHorizontalArrangement-module--ab-usedcar-flow-p5--1CnvD";
export const headingArea = "SearchNewcarHorizontalArrangement-module--heading-area--2ImBs";
export const headingIcon = "SearchNewcarHorizontalArrangement-module--heading-icon--3RWxD";
export const lineupButton = "SearchNewcarHorizontalArrangement-module--lineup-button--1sOKV";
export const usedcarFlow = "SearchNewcarHorizontalArrangement-module--usedcar-flow--hYQ96";