// extracted by mini-css-extract-plugin
export const rankingWrapper = "RankingTop-module--ranking-wrapper--3nNme";
export const headingContainer = "RankingTop-module--heading-container--1dA9d";
export const isPopular = "RankingTop-module--is-popular--vCj3S";
export const isFuel = "RankingTop-module--is-fuel--145jE";
export const isFamily = "RankingTop-module--is-family--hDDie";
export const rankingHeader = "RankingTop-module--ranking-header--24RbX";
export const rankingThumbnail = "RankingTop-module--ranking-thumbnail--DlVN6";
export const rankingComment = "RankingTop-module--ranking-comment--2irie";
export const rankingArrow = "RankingTop-module--ranking-arrow--1HkM1";
export const rankingBackground = "RankingTop-module--ranking-background--1IKB3";
export const rankingCardContainer = "RankingTop-module--ranking-card-container--3Du-7";
export const isPatternFirst = "RankingTop-module--is-pattern-first--2oR8H";
export const isPatternThird = "RankingTop-module--is-pattern-third--5XsF5";
export const hasTabs = "RankingTop-module--has-tabs--2wUBf";
export const isPatternSecond = "RankingTop-module--is-pattern-second--2L74n";
export const isPatternSecondChild = "RankingTop-module--is-pattern-second-child--PbtBw";
export const isRankFirst = "RankingTop-module--is-rank-first--1nz0Q";
export const isRankSecond = "RankingTop-module--is-rank-second--1Hdm3";
export const isRankThird = "RankingTop-module--is-rank-third--3WwB4";
export const isRankFourth = "RankingTop-module--is-rank-fourth--1bMQZ";
export const isRankFifth = "RankingTop-module--is-rank-fifth--28nLw";
export const rankingAccordionContainer = "RankingTop-module--ranking-accordion-container--FcNLH";
export const rankingAccordionButtonContainer = "RankingTop-module--ranking-accordion-button-container--2U-l0";
export const rankingAccordionButton = "RankingTop-module--ranking-accordion-button--3nIEM";
export const isScrollable = "RankingTop-module--is-scrollable--3uWyo";
export const isPatternFourth = "RankingTop-module--is-pattern-fourth--2MJ9R";
export const isTabsFirst = "RankingTop-module--is-tabs-first--3t6QJ";
export const isTabsSecond = "RankingTop-module--is-tabs-second--2sFTZ";
export const isTabsThird = "RankingTop-module--is-tabs-third--34ZYH";
export const rankingTabsLabel = "RankingTop-module--ranking-tabs-label--2JNrX";
export const tabElement01 = "RankingTop-module--tab-element01--132BH";
export const tabElement02 = "RankingTop-module--tab-element02--3usUD";
export const tabElement03 = "RankingTop-module--tab-element03--3w0ad";
export const isNoneFlexGrow = "RankingTop-module--is-none-flex-grow--29zLl";
export const rankingTabsWrapper = "RankingTop-module--ranking-tabs-wrapper--1dHqK";
export const isGrayBorderline = "RankingTop-module--is-gray-borderline--1CC7H";
export const isLetterSpacing = "RankingTop-module--is-letter-spacing--1QsDC";
export const rankingWrapperChild = "RankingTop-module--ranking-wrapper-child--2j2mh";