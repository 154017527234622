import React, {
  FC,
} from 'react';
import * as styles from './DifferencePointTab.module.styl';
// 動的に付与するclass群
import '@/newcar/ui/top/organism/TopPageCustom.styl';

interface Props {
  id: number;
  title: string | JSX.Element;
  text: string | JSX.Element;
  addOrderClass: string;
  notice?: string;
}

// DifferencePointAccordion_ココが違う用
const DifferencePointTab: FC<Props> = ({
  id, title, text, addOrderClass, notice,
}) => (
  <>
    <input
      id={`differenceTab${id}`}
      type="radio"
      name="differenceTab"
      defaultChecked={id === 1}
      className={`difference-tab ${addOrderClass}`}
      readOnly
    />
    <label htmlFor={`differenceTab${id}`} className={`difference-tab-heading ${addOrderClass} has-text-primary`}>
      {title}
      {notice !== null && (
        <>
          <span className={`has-text-black ${styles.differenceTabNotice}`}>
            {notice}
          </span>
        </>
      )}
    </label>

    {/* タブ切り替えで表示される本文 */}
    <div
      className={`${styles.differenceTabContent} difference-tab-element ${addOrderClass}`}
    >
      {text}
    </div>
  </>
);

export default DifferencePointTab;
