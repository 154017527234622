import React, {
  FC,
} from 'react';
import NEWCAR_CONST from '@/newcar/util/const';

// ランキング関連画像
import arrowIconBlack from '@/newcar/ui/common/img/object/icon/arrow-icon-black.svg';

// topのstyle読込
import * as styles from '@/newcar/ui/top/template/index.module.styl';
import * as stylesRank from '@/newcar/ui/top/organism/RankingTop.module.styl';

interface RankingTopProps {
  getMinPrice: (carId: string) => string;
  getThumbnailURL: (carId: string) => string;
}

const RankingTop:FC<RankingTopProps> = ({ getMinPrice, getThumbnailURL }) => {
  // 車両画像をcarDigestから取得
  const nboxThumbnail = getThumbnailURL('10013');
  const miraesThumbnail = getThumbnailURL('10017');
  const hustlerThumbnail = getThumbnailURL('10030');
  const altoThumbnail = getThumbnailURL('10053');
  const spaciaThumbnail = getThumbnailURL('10031');
  const tantoThumbnail = getThumbnailURL('10014');
  const pleoplusThumbnail = getThumbnailURL('10141');
  const flaircrossoverThumbnail = getThumbnailURL('10138');
  const altolapinThumbnail = getThumbnailURL('10204');
  const noteThumbnail = getThumbnailURL('10002');

  // 人気の車ランキング:1位
  const popularRankingNo1Path = `/detail/${NEWCAR_CONST.POPULERRANKING.ID.NO1}`;
  const popularRankingNo1Price = getMinPrice(NEWCAR_CONST.POPULERRANKING.ID.NO1);

  // 人気の車ランキング:2位
  const popularRankingNo2Path = `/detail/${NEWCAR_CONST.POPULERRANKING.ID.NO2}`;
  const popularRankingNo2Price = getMinPrice(NEWCAR_CONST.POPULERRANKING.ID.NO2);

  // 人気の車ランキング:3位
  const popularRankingNo3Path = `/detail/${NEWCAR_CONST.POPULERRANKING.ID.NO3}`;
  const popularRankingNo3Price = getMinPrice(NEWCAR_CONST.POPULERRANKING.ID.NO3);

  // 人気の車ランキング:4位
  const popularRankingNo4Path = `/detail/${NEWCAR_CONST.POPULERRANKING.ID.NO4}`;
  const popularRankingNo4Price = getMinPrice(NEWCAR_CONST.POPULERRANKING.ID.NO4);

  // 人気の車ランキング:5位
  const popularRankingNo5Path = `/detail/${NEWCAR_CONST.POPULERRANKING.ID.NO5}`;
  const popularRankingNo5Price = getMinPrice(NEWCAR_CONST.POPULERRANKING.ID.NO5);

  // 燃費の良い車ランキング:1位
  const fuelRankingNo1Path = `/detail/${NEWCAR_CONST.FUELRANKING.ID.NO1}`;
  const fuelRankingNo1Price = getMinPrice(NEWCAR_CONST.FUELRANKING.ID.NO1);

  // 燃費の良い車ランキング:2位
  const fuelRankingNo2Path = `/detail/${NEWCAR_CONST.FUELRANKING.ID.NO2}`;
  const fuelRankingNo2Price = getMinPrice(NEWCAR_CONST.FUELRANKING.ID.NO2);

  // 燃費の良い車ランキング:3位
  const fuelRankingNo3Path = `/detail/${NEWCAR_CONST.FUELRANKING.ID.NO3}`;
  const fuelRankingNo3Price = getMinPrice(NEWCAR_CONST.FUELRANKING.ID.NO3);

  // 燃費の良い車ランキング:4位
  const fuelRankingNo4Path = `/detail/${NEWCAR_CONST.FUELRANKING.ID.NO4}`;
  const fuelRankingNo4Price = getMinPrice(NEWCAR_CONST.FUELRANKING.ID.NO4);

  // 燃費の良い車ランキング:5位
  const fuelRankingNo5Path = `/detail/${NEWCAR_CONST.FUELRANKING.ID.NO5}`;
  const fuelRankingNo5Price = getMinPrice(NEWCAR_CONST.FUELRANKING.ID.NO5);

  // ファミリー層人気ランキング:1位
  const familyRankingNo1Path = `/detail/${NEWCAR_CONST.FAMILYRANKING.ID.NO1}`;
  const familyRankingNo1Price = getMinPrice(NEWCAR_CONST.FAMILYRANKING.ID.NO1);

  // ファミリー層人気ランキング:2位
  const familyRankingNo2Path = `/detail/${NEWCAR_CONST.FAMILYRANKING.ID.NO2}`;
  const familyRankingNo2Price = getMinPrice(NEWCAR_CONST.FAMILYRANKING.ID.NO2);

  // ファミリー層人気ランキング:3位
  const familyRankingNo3Path = `/detail/${NEWCAR_CONST.FAMILYRANKING.ID.NO3}`;
  const familyRankingNo3Price = getMinPrice(NEWCAR_CONST.FAMILYRANKING.ID.NO3);

  // ファミリー層人気ランキング:4位
  const familyRankingNo4Path = `/detail/${NEWCAR_CONST.FAMILYRANKING.ID.NO4}`;
  const familyRankingNo4Price = getMinPrice(NEWCAR_CONST.FAMILYRANKING.ID.NO4);

  // ファミリー層人気ランキング:5位
  const familyRankingNo5Path = `/detail/${NEWCAR_CONST.FAMILYRANKING.ID.NO5}`;
  const familyRankingNo5Price = getMinPrice(NEWCAR_CONST.FAMILYRANKING.ID.NO5);

  return (
    <>

      {/* tab切り替え */}
      <section id="ranking-original" className={`${stylesRank.isPatternFourth}`}>
        <div className={`${stylesRank.rankingBackground} ${stylesRank.hasTabs}`}>

          <div className="container is-margin-auto">
            <h2 className={`is-3 has-text-centered ${styles.contentsTitle} ${styles.isRanking}`}>
              カルモくんオススメの車ランキング
            </h2>
            <div className={`${stylesRank.rankingWrapper}`}>

              <div className={`columns is-mobile ${stylesRank.rankingTabsWrapper}`}>
                {/* tabヘッダ部分 */}
                { /* eslint-disable jsx-a11y/label-has-associated-control */ }
                <input id="rankigRadioPopular" type="radio" name="rankigRadio" defaultChecked className={`${stylesRank.isTabsFirst}`} />
                <label htmlFor="rankigRadioPopular" className={`${stylesRank.rankingTabsLabel}`}>人気</label>

                <input id="rankigRadioFuel" type="radio" name="rankigRadio" className={`${stylesRank.isTabsSecond}`} />
                <label htmlFor="rankigRadioFuel" className={`${stylesRank.rankingTabsLabel}`}>燃費が良い</label>

                <input type="radio" id="rankigRadioFamily" name="rankigRadio" className={`${stylesRank.isTabsThird}`} />
                <label htmlFor="rankigRadioFamily" className={`${stylesRank.rankingTabsLabel}`}>ファミリー向け</label>
                {/* tabヘッダ部分 */}

                {/* tab表示要素 */}
                <div className={`container ${stylesRank.tabElement01}`}>
                  {/* 人気ランキング */}
                  <div className={`is-relative ${stylesRank.headingContainer} ${stylesRank.isPopular}`}>
                    <span className="is-size-5 is-block">いま選ばれている車をまずはチェック！</span>
                    <h3 className="is-size-3 has-text-weight-bold">
                      人気ランキング
                    </h3>
                  </div>
                  <div className={`columns ${stylesRank.rankingCardContainer}`}>
                    {/*  1  */}
                    <div className={`box recommend-10013 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFirst}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={popularRankingNo1Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={nboxThumbnail}
                                  className="recommend-img"
                                  alt="N BOX"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">ホンダ</strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">N BOX</strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{popularRankingNo1Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            スーパーハイトワゴンのみならず軽自動車全体をリードするベストセラー
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* // 1  */}

                    {/*  2  */}
                    <div className={`box recommend-10030 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankSecond}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={popularRankingNo2Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={hustlerThumbnail}
                                  className="recommend-img"
                                  alt="ハスラー"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  スズキ
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">ハスラー</strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{popularRankingNo2Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            使い勝手の良さと走破性に加え、ユニークなエクステリアが魅力の大ヒット軽クロスオーバーSUV
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* // 2  */}

                    {/*  3  */}
                    <div className={`box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankThird}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={popularRankingNo3Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={miraesThumbnail}
                                  className="recommend-img"
                                  alt="ミライース"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  ダイハツ
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">
                                  ミライース
                                </strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{popularRankingNo3Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            ただ安いだけではない、シンプルかつシックな軽自動車のスタンダード
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* //  3  */}

                    {/*  4  */}
                    <div className={`box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative is-hidden-tablet ${stylesRank.isRankFourth}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={popularRankingNo4Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={spaciaThumbnail}
                                  className="recommend-img"
                                  alt="スペーシア"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  スズキ
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">
                                  スペーシア
                                </strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{popularRankingNo4Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            フロントデザインが新しくなったハイブリッド・スーパーハイトワゴン
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* //  4  */}

                    {/*  5  */}
                    <div className={`box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative is-hidden-tablet ${stylesRank.isRankFifth}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={popularRankingNo5Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={tantoThumbnail}
                                  className="recommend-img"
                                  alt="タント"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  ダイハツ
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">
                                  タント
                                </strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{popularRankingNo5Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            「ミラクルオープンドア」が子育て世帯にうれしい、軽トールワゴンの始祖
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* //  5  */}

                    {/* // 人気ランキング */}
                  </div>

                </div>

                <div className={`container ${stylesRank.tabElement02}`}>
                  {/* 燃費の良い車ランキング */}
                  <div className={`is-relative ${stylesRank.headingContainer} ${stylesRank.isFuel}`}>
                    <span className="is-size-5 is-block">維持費を抑えてコスパよく乗るならこの車！</span>
                    <h3 className="is-size-3 has-text-weight-bold">
                      燃費の良い車ランキング
                    </h3>
                  </div>
                  <div className={`columns ${stylesRank.rankingCardContainer}`}>
                    {/*  1  */}
                    <div className={`box recommend-10013 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFirst}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={fuelRankingNo1Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={altoThumbnail}
                                  className="recommend-img"
                                  alt="アルト"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">スズキ</strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">アルト</strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{fuelRankingNo1Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            フルモデルチェンジして原点回帰したベーシック軽自動車の決定版！ハイブリッドも登場
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* // 1  */}

                    {/*  2  */}
                    <div className={`box recommend-10030 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankSecond}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={fuelRankingNo2Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={miraesThumbnail}
                                  className="recommend-img"
                                  alt="ミライース"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  ダイハツ
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">ミライース</strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{fuelRankingNo2Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            ただ安いだけではない、シンプルかつシックな軽自動車のスタンダード
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* // 2  */}

                    {/*  3  */}
                    <div className={`box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankThird}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={fuelRankingNo3Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={pleoplusThumbnail}
                                  className="recommend-img"
                                  alt="プレオプラス"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  スバル
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">
                                  プレオプラス
                                </strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{fuelRankingNo3Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            最新の安全装備が充実しながら、100万円以下で購入できる軽ベーシックモデル
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* //  3  */}

                    {/*  4  */}
                    <div className={`is-hidden-tablet box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFourth}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={fuelRankingNo4Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={flaircrossoverThumbnail}
                                  className="recommend-img"
                                  alt="フレアクロスオーバー"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  マツダ
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">
                                  フレアクロスオーバー
                                </strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{fuelRankingNo4Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            ゆとりのある室内空間とSUVの走破性を融合させた個性派軽クロスオーバーSUV
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* //  4  */}

                    {/*  5  */}
                    <div className={`is-hidden-tablet box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFifth}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={fuelRankingNo5Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={altolapinThumbnail}
                                  className="recommend-img"
                                  alt="アルトラパン"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  スズキ
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">
                                  アルトラパン
                                </strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{fuelRankingNo5Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            「まるしかくい」独特のキュートなデザインや低燃費が魅力の毎日が楽しくなる軽セダン
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* //  5  */}
                  </div>
                  {/* // 燃費の良い車ランキング */}
                </div>

                <div className={`container ${stylesRank.tabElement03}`}>
                  {/* ファミリー層人気ランキング */}
                  <div className={`is-relative ${stylesRank.headingContainer} ${stylesRank.isFamily}`}>
                    <span className="is-size-5 is-block">車内広々、子供も大人も嬉しい機能が充実！</span>
                    <h3 className="is-size-3 has-text-weight-bold">
                      ファミリー層人気ランキング
                    </h3>
                  </div>
                  <div className={`columns ${stylesRank.rankingCardContainer}`}>
                    {/*  1  */}
                    <div className={`box recommend-10013 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankFirst}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={familyRankingNo1Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={nboxThumbnail}
                                  className="recommend-img"
                                  alt="N BOX"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">ホンダ</strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">N BOX</strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{familyRankingNo1Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            スーパーハイトワゴンのみならず軽自動車全体をリードするベストセラー
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* // 1  */}

                    {/*  2  */}
                    <div className={`box recommend-10030 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankSecond}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={familyRankingNo2Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={hustlerThumbnail}
                                  className="recommend-img"
                                  alt="ハスラー"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  スズキ
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">ハスラー</strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{familyRankingNo2Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            使い勝手の良さと走破性に加え、ユニークなエクステリアが魅力の大ヒット軽クロスオーバーSUV
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* // 2  */}

                    {/*  3  */}
                    <div className={`box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative ${stylesRank.isRankThird}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={familyRankingNo3Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={spaciaThumbnail}
                                  className="recommend-img"
                                  alt="スペーシア"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  スズキ
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">
                                  スペーシア
                                </strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{familyRankingNo3Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            フロントデザインが新しくなったハイブリッド・スーパーハイトワゴン
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* //  3  */}

                    {/*  4  */}
                    <div className={`box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative is-hidden-tablet ${stylesRank.isRankFourth}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={familyRankingNo4Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={tantoThumbnail}
                                  className="recommend-img"
                                  alt="タント"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  ダイハツ
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">
                                  タント
                                </strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{familyRankingNo4Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            「ミラクルオープンドア」が子育て世帯にうれしい、軽トールワゴンの始祖
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* //  4  */}

                    {/*  5 */}
                    <div className={`box recommend-10016 column is-margin-3 is-margin-bottom-5 is-relative is-hidden-tablet ${stylesRank.isRankFifth}`}>
                      <div className="is-relative">
                        <a className="recommend-url" href={familyRankingNo5Path}>
                          <div className={`columns is-mobile is-vcentered ${stylesRank.rankingHeader}`}>
                            <div className={`column card-image is-padding-right-3 ${stylesRank.isNoneFlexGrow}`}>
                              <figure className={`image ${stylesRank.rankingThumbnail}`}>
                                <img
                                  src={noteThumbnail}
                                  className="recommend-img"
                                  alt="ノート"
                                />
                              </figure>
                            </div>
                            <div className="column">
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-maker-name">
                                  日産
                                </strong>
                              </div>
                              <div className="is-size-5 has-text-black-ter">
                                <strong className="recommend-name">
                                  ノート
                                </strong>
                              </div>
                              <div className="is-size-3 has-text-black-ter">
                                <strong className="lowest-price">{familyRankingNo5Price}</strong>
                                <span className="is-size-6 has-text-grey-light">
                                  円～/月
                                </span>
                              </div>
                              <div className="">
                                <span className="is-size-7 has-text-grey ranking-tag">
                                  頭金0円
                                </span>
                                <span className="is-size-7 has-text-grey ranking-tag is-margin-left-2">
                                  ボーナス払い0円
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={`comment is-size-5 has-text-black-ter is-margin-top-3 ${stylesRank.rankingComment}`}>
                            新世代の日産デザインと全車e-POWERの走りで刷新されたベストセラーコンパクトカー
                          </div>
                          <i className={`icon is-small ${stylesRank.rankingArrow}`}>
                            <img src={arrowIconBlack} alt="矢印アイコン" />
                          </i>
                        </a>
                      </div>
                    </div>
                    {/* //  5  */}

                  </div>
                  {/* // ファミリー層人気ランキング */}
                </div>

                {/* // tab表示要素 */}

              </div>

            </div>
          </div>
        </div>
      </section>
      {/* //tab切り替え */}
    </>
  );
};

export default RankingTop;
