import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import NEWCAR_DEFINE from '@/newcar/util/define';
import CARMO_CONST from '@/common/util/const';

import newcarIcon from '@/newcar/ui/common/img/object/icon/newcar-icon.svg';
import newcarIconSp from '@/newcar/ui/common/img/object/icon/newcar-icon-sp.svg';

import toyota from '@/newcar/ui/common/img/maker/toyota.png';
import nissan from '@/newcar/ui/common/img/maker/nissan.png';
import honda from '@/newcar/ui/common/img/maker/honda.png';
import mazda from '@/newcar/ui/common/img/maker/mazda.png';
import subaru from '@/newcar/ui/common/img/maker/subaru.png';
import suzuki from '@/newcar/ui/common/img/maker/suzuki.png';
import daihatsu from '@/newcar/ui/common/img/maker/daihatsu.png';
import mitsubishi from '@/newcar/ui/common/img/maker/mitsubishi.png';

import sedan from '@/newcar/ui/common/img/bodytype/black/sedan.svg';
import stationwagon from '@/newcar/ui/common/img/bodytype/black/stationwagon.svg';
import minivan from '@/newcar/ui/common/img/bodytype/black/minivan.svg';
import suv from '@/newcar/ui/common/img/bodytype/black/suv.svg';
import mini from '@/newcar/ui/common/img/bodytype/black/mini.svg';
import compact from '@/newcar/ui/common/img/bodytype/black/compact.svg';
import hatchback from '@/newcar/ui/common/img/bodytype/black/hatchback.svg';
import coupe from '@/newcar/ui/common/img/bodytype/black/coupe.svg';

import FreeWordSearchBox from '@/newcar/ui/top/organism/FreeWordSearchBox';

import * as styles from './SearchNewcar.module.styl';

interface State {
  isMakerModalActive: boolean
  isBodyTypeModalActive: boolean
}

const SearchNewcar: FC = () => {
  const [state, setState] = useState<State>({
    isMakerModalActive: false,
    isBodyTypeModalActive: false,
  });

  const toggleMakerModal = (): void => {
    const { isMakerModalActive } = state;
    setState({
      isBodyTypeModalActive: false,
      isMakerModalActive: !isMakerModalActive,
    });
  };

  const toggleBodyTypeModal = (): void => {
    const { isBodyTypeModalActive } = state;
    setState({
      isMakerModalActive: false,
      isBodyTypeModalActive: !isBodyTypeModalActive,
    });
  };

  return (
    <div className={`column ${styles.newcarSearchArea}`}>
      <div className={`is-hidden-tablet ${styles.semicircle}`} />
      <div className="is-hidden-mobile columns is-multiline is-vcentered sp-search-box-positon">
        <div className="column columns is-margin-wide-0 is-full">
          <div className="column">
            <div className="columns is-mobile">
              <div className="column">
                <div
                  className={`columns is-mobile is-centered-mobile is-vcentered ${styles.spSearchHeadingPosition}`}
                >
                  <div className="column is-narrow is-margin-right-3">
                    <img
                      src={newcarIcon}
                      alt="車のアイコン"
                      className={`is-hidden-mobile ${styles.newcarIconPositon}`}
                    />
                    <img
                      src={newcarIconSp}
                      alt="車のアイコン"
                      className={`is-hidden-tablet ${styles.newcarIconPositon}`}
                    />
                  </div>
                  <div className="column is-narrow">
                    <h2 className={styles.newcarHeading}>
                      <strong className="is-size-2 has-text-white">
                        新車カーリース
                      </strong>
                      を探す
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`columns is-mobile is-multiline ${styles.topSearch}`}
            >
              <div className="column is-half-tablet is-padding-2">
                <div className={styles.top_search_button}>
                  <Link
                    className="button is-padding-2-desktop has-background-redblack has-text-white"
                    to={NEWCAR_DEFINE.PATH.LINEUP_TOP}
                  >
                    <div>
                      <strong className="is-size-4">新車一覧</strong>
                    </div>
                    <span className="icon-arrow-right is-hidden-desktop" />
                    <span className="icon-arrow-right-big is-hidden-touch" />
                  </Link>
                </div>
              </div>

              {/*  メーカーから探す  */}
              <div className="column is-half-tablet is-padding-2">
                <div className={styles.top_search_button}>
                  <a
                    onClick={toggleMakerModal}
                    href="#modal"
                    className="button is-padding-2-desktop has-background-redblack has-text-white show-makerModal"
                    data-target="maker-modal"
                  >
                    <div>
                      <strong className="is-size-4-tablet is-size-5-mobile">
                        メーカー
                      </strong>
                      <br className="is-hidden-tablet" />
                      <span className={`is-size-6 ${styles.searchText}`}>から選ぶ</span>
                    </div>
                    <span className="icon-arrow-right is-hidden-desktop" />
                    <span className="icon-arrow-right-big is-hidden-touch" />
                  </a>
                </div>
              </div>

              {/* maker modal */}
              <div
                id="maker-modal"
                className={`modal is-zindex-modal ${
                  state.isMakerModalActive ? 'is-active' : ''
                }`}
              >
                <div
                  className="maker-bg modal-background"
                  data-target="maker-modal"
                />
                <div className="modal-card is-margin-0">
                  <div className="accent-line-long has-background-primary" />
                  <section className="modal-card-body is-padding-4">
                    <div className="columns is-mobile is-vcentered is-margin-top-5 is-margin-bottom-5">
                      <div className="column">
                        <h2 className="title is-size-3 has-text-weight-bold has-text-primary is-margin-left-3">
                          メーカーから探す
                        </h2>
                      </div>
                      <div className="column is-narrow">
                        <a
                          onClick={toggleMakerModal}
                          href="#modal"
                          className="delete del_maker is-large is-margin-right-3"
                          data-target="maker-modal"
                        >
                          閉じる
                        </a>
                      </div>
                    </div>

                    <div className="columns is-mobile is-multiline">
                      <div className="column is-one-third">
                        <Link
                          to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.TOYOTA)}
                          className="box is-margin-3 is-relative"
                        >
                          <input
                            type="checkbox"
                            name="maker"
                            className={styles.modal_maker_input}
                          />
                          <div
                            className={`is-flex ${styles.modal_maker_items}`}
                          >
                            <em
                              className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                            >
                              トヨタ
                            </em>
                            <img src={toyota} alt="トヨタ" />
                          </div>
                        </Link>
                      </div>
                      <div className="column is-one-third">
                        <Link
                          to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.NISSAN)}
                          className="box is-margin-3 is-relative"
                        >
                          <div
                            className={`is-flex ${styles.modal_maker_items}`}
                          >
                            <em
                              className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                            >
                              日産
                            </em>
                            <img src={nissan} alt="日産" />
                          </div>
                        </Link>
                      </div>
                      <div className="column is-one-third">
                        <Link
                          to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.HONDA)}
                          className="box is-margin-3 is-relative"
                        >
                          <div
                            className={`is-flex ${styles.modal_maker_items}`}
                          >
                            <em
                              className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                            >
                              ホンダ
                            </em>
                            <img src={honda} alt="ホンダ" />
                          </div>
                        </Link>
                      </div>
                      <div className="column is-one-third">
                        <Link
                          to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.MAZDA)}
                          className="box is-margin-3 is-relative"
                        >
                          <div
                            className={`is-flex ${styles.modal_maker_items}`}
                          >
                            <em
                              className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                            >
                              マツダ
                            </em>
                            <img src={mazda} alt="マツダ" />
                          </div>
                        </Link>
                      </div>
                      <div className="column is-one-third">
                        <Link
                          to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.SUBARU)}
                          className="box is-margin-3 is-relative"
                        >
                          <div
                            className={`is-flex ${styles.modal_maker_items}`}
                          >
                            <em
                              className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                            >
                              スバル
                            </em>
                            <img src={subaru} alt="スバル" />
                          </div>
                        </Link>
                      </div>
                      <div className="column is-one-third">
                        <Link
                          to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.SUZUKI)}
                          className="box is-margin-3 is-relative"
                        >
                          <div
                            className={`is-flex ${styles.modal_maker_items}`}
                          >
                            <em
                              className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                            >
                              スズキ
                            </em>
                            <img src={suzuki} alt="スズキ" />
                          </div>
                        </Link>
                      </div>
                      <div className="column is-one-third">
                        <Link
                          to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.DAIHATSU)}
                          className="box is-margin-3 is-relative"
                        >
                          <div
                            className={`is-flex ${styles.modal_maker_items}`}
                          >
                            <em
                              className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                            >
                              ダイハツ
                            </em>
                            <img src={daihatsu} alt="ダイハツ" />
                          </div>
                        </Link>
                      </div>
                      <div className="column is-one-third">
                        <Link
                          to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.MITSUBISHI)}
                          className="box is-margin-3 is-relative"
                        >
                          <div
                            className={`is-flex ${styles.modal_maker_items}`}
                          >
                            <em
                              className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                            >
                              三菱
                            </em>
                            <img src={mitsubishi} alt="三菱" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <a
                      onClick={toggleMakerModal}
                      href="#modal"
                      className="cansel_maker is-pulled-right is-margin-3 is-margin-bottom-7"
                      data-target="maker-modal"
                    >
                      キャンセル
                    </a>
                  </section>
                </div>
              </div>

              <div className="column is-half-tablet is-padding-2">
                <div className={styles.top_search_button}>
                  <a
                    onClick={toggleBodyTypeModal}
                    href="#modal"
                    className="button is-padding-2-desktop has-background-redblack has-text-white show-carModal column-boxshadow"
                    data-target="car-modal"
                  >
                    <div>
                      <strong className="is-size-4-tablet is-size-5-mobile">
                        車のタイプ
                      </strong>
                      <br className="is-hidden-tablet" />
                      <span className={`is-size-6 ${styles.searchText}`}>から選ぶ</span>
                    </div>
                    <span className="icon-arrow-right is-hidden-desktop" />
                    <span className="icon-arrow-right-big is-hidden-touch" />
                  </a>
                </div>
              </div>

              {/* 金額から選ぶ */}
              <div id="ab-target202211-choose-from-fee" className={`is-hidden-tablet is-hidden has-background-redblack has-text-white ${styles.topSearchFeeRangeWrapper}`}>
                <h3 className={`${styles.topSearchFeeRangeTitle}`}>
                  <span className={`${styles.isLarge}`}>金額</span>
                  から選ぶ
                </h3>
                <div className={`columns is-mobile is-multiline ${styles.topSearchFeeRangeContainer}`}>
                  <div className={`column ${styles.topSearchFeeRangeButton}`}>
                    <Link
                      className={`button has-background-white has-text-black ${styles.topSearchFeeRangeLink}`}
                      to="/lineup/list/?term=11&price=1"
                      data-gtm="gtm-top-1man"
                    >
                      <span className={`${styles.isLarge}`}>1万円</span>
                      台の車
                    </Link>
                  </div>
                  <div className={`column ${styles.topSearchFeeRangeButton}`}>
                    <Link
                      className={`button has-background-white has-text-black ${styles.topSearchFeeRangeLink}`}
                      to="/lineup/list/?term=11&price=2"
                      data-gtm="gtm-top-2man"
                    >
                      <span className={`${styles.isLarge}`}>2万円</span>
                      台の車
                    </Link>
                  </div>
                  <div className={`column ${styles.topSearchFeeRangeButton}`}>
                    <Link
                      className={`button has-background-white has-text-black ${styles.topSearchFeeRangeLink}`}
                      to="/lineup/list/?term=11&price=3"
                      data-gtm="gtm-top-3man"
                    >
                      <span className={`${styles.isLarge}`}>3万円</span>
                      台の車
                    </Link>
                  </div>
                </div>
              </div>
              {/* // 金額から選ぶ */}

              {/* bodyType modal */}
              <div
                id="car-modal"
                className={`modal is-zindex-modal ${
                  state.isBodyTypeModalActive ? 'is-active' : ''
                }`}
              >
                <div
                  className="car-bg modal-background"
                  data-target="car-modal"
                />
                <div className="modal-card is-margin-0">
                  <div className="accent-line-long has-background-primary" />
                  <section className="modal-card-body is-padding-4">
                    <div className="columns is-mobile is-vcentered is-margin-top-5 is-margin-bottom-5">
                      <div className="column">
                        <h2 className="title is-size-3 has-text-weight-bold has-text-primary is-margin-left-3">
                          キーワードから探す
                        </h2>
                      </div>
                      <div className="column is-narrow">
                        <a
                          onClick={toggleBodyTypeModal}
                          href="#modal"
                          className="delete del_car is-large is-margin-right-3"
                          data-target="car-modal"
                        >
                          閉じる
                        </a>
                      </div>
                    </div>
                    <div className="columns is-mobile is-multiline">
                      <div className="columns is-mobile is-multiline">
                        <div className="column is-one-third">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                              CARMO_CONST.BODY_TYPE.ID.MINI_CAR,
                            )}
                            className="box is-margin-3 is-relative"
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <em
                                className={`is-size-5 is-size-7-mobile has-text-centered ${styles.modal_body_type_one_text}`}
                              >
                                軽自動車
                              </em>
                              <img src={mini} alt="軽自動車" />
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-third">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                              CARMO_CONST.BODY_TYPE.ID.COMPACT_CAR,
                            )}
                            className="box is-margin-3 is-relative"
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <em
                                className={`is-size-5 is-size-7-mobile has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                コンパクト
                                <br />
                                カー
                              </em>
                              <img
                                src={compact}
                                alt="コンパクトカー"
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-third">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.MINIVAN)}
                            className="box is-margin-3 is-relative"
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <em
                                className={`is-size-5 is-size-7-mobile has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                ミニバン
                                <br />
                                ワゴン
                              </em>
                              <img
                                src={minivan}
                                alt="ミニバン・ワゴン"
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-third">
                          {' '}
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.SUV)}
                            className="box is-margin-3 is-relative"
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <em
                                className={`is-size-5 is-size-7-mobile has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                SUV
                                <br />
                                クロカン
                              </em>
                              <img
                                src={suv}
                                alt="SUV・クロカン"
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-third">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.SEDAN)}
                            className="box is-margin-3 is-relative"
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <em
                                className={`is-size-5 is-size-7-mobile has-text-centered ${styles.modal_body_type_one_text}`}
                              >
                                セダン
                              </em>
                              <img src={sedan} alt="セダン" />
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-third">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                              CARMO_CONST.BODY_TYPE.ID.HATCH_BACK,
                            )}
                            className="box is-margin-3 is-relative"
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <em
                                className={`is-size-5 is-size-7-mobile has-text-centered ${styles.modal_body_type_one_text}`}
                              >
                                ハッチバック
                              </em>
                              <img
                                src={hatchback}
                                alt="ハッチバック"
                                width="62px"
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-third">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                              CARMO_CONST.BODY_TYPE.ID.STATION_WAGON,
                            )}
                            className="box is-margin-3 is-relative"
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <em
                                className={`is-size-5 is-size-7-mobile has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                ステーション
                                <br />
                                ワゴン
                              </em>
                              <img
                                src={stationwagon}
                                alt="ステーションワゴン"
                              />
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-third">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                              CARMO_CONST.BODY_TYPE.ID.COUPE,
                            )}
                            className="box is-margin-3 is-relative"
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <em
                                className={`is-size-5 is-size-7-mobile has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                クーペ
                                <br />
                                オープンカー
                              </em>
                              <img
                                src={coupe}
                                alt="クーペ・オープンカー"
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <a
                      onClick={toggleBodyTypeModal}
                      href="#modal"
                      className="cansel_car is-pulled-right is-margin-3 is-margin-bottom-7"
                      data-target="car-modal"
                    >
                      キャンセル
                    </a>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  キーワード検索  */}
        <div className="column is-margin-3 is-margin-bottom-3">
          <FreeWordSearchBox />
          <p className="is-size-6 has-text-danger is-margin-left-1 is-margin-right-1 is-hidden">
            該当する車種は見つかりませんでした。キーワードを変更して、もう一度お試しください。
          </p>
        </div>
      </div>

      <div className="is-hidden-tablet columns is-multiline is-vcentered sp-search-box-positon is-relative">
        <div className="column columns is-margin-wide-0 is-full">
          <div className="column">
            <div className="columns is-mobile">
              <div className="column">
                <div
                  className={`columns is-mobile is-centered-mobile is-vcentered ${styles.spSearchHeadingPosition}`}
                >
                  <div className="column is-narrow is-margin-right-3">
                    <img
                      src={newcarIcon}
                      alt="車のアイコン"
                      className={`is-hidden-mobile ${styles.newcarIconPositon}`}
                    />
                    <img
                      src={newcarIconSp}
                      alt="車のアイコン"
                      className={`is-hidden-tablet ${styles.newcarIconPositon}`}
                    />
                  </div>
                  <div className="column is-narrow">
                    <h2 className={styles.newcarHeading}>
                      <strong className="is-size-2 has-text-white">
                        新車カーリース
                      </strong>
                      を探す
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className={`is-mobile is-multiline has-background-white radius-mini is-padding-4 is-padding-top-6 ${styles.topSearch}`}>
              {/*  新車一覧  */}
              <div className="is-margin-bottom-7">
                <h2 className="has-text-weight-semibold is-margin-bottom-3">
                  新車の一覧から探す
                </h2>
                <div className="column is-full">
                  <div className={styles.top_search_button_ab}>
                    <Link
                      className="button is-padding-2-desktop has-background-white is-rounded"
                      to={NEWCAR_DEFINE.PATH.LINEUP_TOP}
                    >
                      <p className="is-size-4">
                        新車の一覧
                        <span
                          className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                        />
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              {/*  メーカーから探す  */}
              <div className="is-margin-bottom-7">
                <h2 className="has-text-weight-semibold is-margin-bottom-3">
                  メーカーから探す
                </h2>
                <div className="columns is-1 is-mobile is-multiline is-variable">
                  <div className="column is-one-quarter is-margin-bottom-3">
                    <Link
                      to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.TOYOTA)}
                      className={`box is-relative ${styles.boxAdjust}`}
                    >
                      <input
                        type="checkbox"
                        name="maker"
                        className={styles.modal_maker_input}
                      />
                      <div
                        className={`is-flex ${styles.modal_maker_icons}`}
                      >
                        <img src={toyota} alt="トヨタ" />
                        <em
                          className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                        >
                          トヨタ
                        </em>
                      </div>
                    </Link>
                  </div>
                  <div className="column is-one-quarter is-margin-bottom-3">
                    <Link
                      to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.NISSAN)}
                      className={`box is-relative ${styles.boxAdjust}`}
                    >
                      <div
                        className={`is-flex ${styles.modal_maker_icons}`}
                      >
                        <img src={nissan} alt="日産" />
                        <em
                          className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                        >
                          日産
                        </em>
                      </div>
                    </Link>
                  </div>
                  <div className="column is-one-quarter is-margin-bottom-3">
                    <Link
                      to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.HONDA)}
                      className={`box is-relative ${styles.boxAdjust}`}
                    >
                      <div
                        className={`is-flex ${styles.modal_maker_icons}`}
                      >
                        <img src={honda} alt="ホンダ" />
                        <em
                          className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                        >
                          ホンダ
                        </em>
                      </div>
                    </Link>
                  </div>
                  <div className="column is-one-quarter is-margin-bottom-3">
                    <Link
                      to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.MAZDA)}
                      className={`box is-relative ${styles.boxAdjust}`}
                    >
                      <div
                        className={`is-flex ${styles.modal_maker_icons}`}
                      >
                        <img src={mazda} alt="マツダ" />
                        <em
                          className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                        >
                          マツダ
                        </em>
                      </div>
                    </Link>
                  </div>
                  <div className="column is-one-quarter">
                    <Link
                      to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.SUBARU)}
                      className={`box is-relative ${styles.boxAdjust}`}
                    >
                      <div
                        className={`is-flex ${styles.modal_maker_icons}`}
                      >
                        <img src={subaru} alt="スバル" />
                        <em
                          className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                        >
                          スバル
                        </em>
                      </div>
                    </Link>
                  </div>
                  <div className="column is-one-quarter">
                    <Link
                      to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.SUZUKI)}
                      className={`box is-relative ${styles.boxAdjust}`}
                    >
                      <div
                        className={`is-flex ${styles.modal_maker_icons}`}
                      >
                        <img src={suzuki} alt="スズキ" />
                        <em
                          className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                        >
                          スズキ
                        </em>
                      </div>
                    </Link>
                  </div>
                  <div className="column is-one-quarter">
                    <Link
                      to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.DAIHATSU)}
                      className={`box is-relative ${styles.boxAdjust}`}
                    >
                      <div
                        className={`is-flex ${styles.modal_maker_icons}`}
                      >
                        <img src={daihatsu} alt="ダイハツ" />
                        <em
                          className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                        >
                          ダイハツ
                        </em>
                      </div>
                    </Link>
                  </div>
                  <div className="column is-one-quarter">
                    <Link
                      to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.MITSUBISHI)}
                      className={`box is-relative ${styles.boxAdjust}`}
                    >
                      <div
                        className={`is-flex ${styles.modal_maker_icons}`}
                      >
                        <img src={mitsubishi} alt="三菱" />
                        <em
                          className={`is-size-5 is-size-6-mobile ${styles.modal_maker_nostyle}`}
                        >
                          三菱
                        </em>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              {/* ボディタイプから探す */}
              <div className="is-margin-bottom-7 ">
                <h2 className="has-text-weight-semibold is-margin-bottom-3">
                  車のタイプから探す
                </h2>
                <div className="columns is-1 is-mobile is-multiline is-variable">
                  <div className={`columns is-mobile is-multiline ${styles.isTabletFullWidth}`}>
                    <div className="column is-one-quarter is-margin-bottom-3">
                      <Link
                        to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                          CARMO_CONST.BODY_TYPE.ID.MINI_CAR,
                        )}
                        className={`box is-relative ${styles.boxAdjust}`}
                      >
                        <div
                          className={`is-flex ${styles.modal_body_type_label}`}
                        >
                          <img src={mini} alt="軽自動車" />
                          <em
                            className={`is-size-5-desktop is-size-7 has-text-centered ${styles.modal_body_type_one_text}`}
                          >
                            軽自動車
                          </em>
                        </div>
                      </Link>
                    </div>
                    <div className="column is-one-quarter is-margin-bottom-3">
                      <Link
                        to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                          CARMO_CONST.BODY_TYPE.ID.COMPACT_CAR,
                        )}
                        className={`box is-relative ${styles.boxAdjust}`}
                      >
                        <div
                          className={`is-flex ${styles.modal_body_type_label}`}
                        >
                          <img
                            src={compact}
                            alt="コンパクトカー"
                          />
                          <em
                            className={`is-size-5-desktop is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                          >
                            コンパクト
                            <br />
                            カー
                          </em>
                        </div>
                      </Link>
                    </div>
                    <div className="column is-one-quarter is-margin-bottom-3">
                      <Link
                        to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.MINIVAN)}
                        className={`box is-relative ${styles.boxAdjust}`}
                      >
                        <div
                          className={`is-flex ${styles.modal_body_type_label}`}
                        >
                          <img
                            src={minivan}
                            alt="ミニバン・ワゴン"
                          />
                          <em
                            className={`is-size-5-desktop is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                          >
                            ミニバン
                            <br />
                            ワゴン
                          </em>
                        </div>
                      </Link>
                    </div>
                    <div className="column is-one-quarter is-margin-bottom-3">
                      <Link
                        to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.SUV)}
                        className={`box is-relative ${styles.boxAdjust}`}
                      >
                        <div
                          className={`is-flex ${styles.modal_body_type_label}`}
                        >
                          <img
                            src={suv}
                            alt="SUV・クロカン"
                          />
                          <em
                            className={`is-size-5-desktop is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                          >
                            SUV
                            <br />
                            クロカン
                          </em>
                        </div>
                      </Link>
                    </div>
                    <div className="column is-one-quarter">
                      <Link
                        to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.SEDAN)}
                        className={`box is-relative ${styles.boxAdjust}`}
                      >
                        <div
                          className={`is-flex ${styles.modal_body_type_label}`}
                        >
                          <img src={sedan} alt="セダン" />
                          <em
                            className={`is-size-5-desktop is-size-7 has-text-centered ${styles.modal_body_type_one_text}`}
                          >
                            セダン
                          </em>
                        </div>
                      </Link>
                    </div>
                    <div className="column is-one-quarter">
                      <Link
                        to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                          CARMO_CONST.BODY_TYPE.ID.HATCH_BACK,
                        )}
                        className={`box is-relative ${styles.boxAdjust}`}
                      >
                        <div
                          className={`is-flex ${styles.modal_body_type_label}`}
                        >
                          <img
                            src={hatchback}
                            alt="ハッチバック"
                          />
                          <em
                            className={`is-size-5-desktop is-size-7 has-text-centered ${styles.modal_body_type_one_text}`}
                          >
                            ハッチバック
                          </em>
                        </div>
                      </Link>
                    </div>
                    <div className="column is-one-quarter">
                      <Link
                        to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                          CARMO_CONST.BODY_TYPE.ID.STATION_WAGON,
                        )}
                        className={`box is-relative ${styles.boxAdjust}`}
                      >
                        <div
                          className={`is-flex ${styles.modal_body_type_label}`}
                        >
                          <img
                            src={stationwagon}
                            alt="ステーションワゴン"
                          />
                          <em
                            className={`is-size-5-desktop is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                          >
                            ステーションワゴン
                          </em>
                        </div>
                      </Link>
                    </div>
                    <div className="column is-one-quarter">
                      <Link
                        to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                          CARMO_CONST.BODY_TYPE.ID.COUPE,
                        )}
                        className={`box is-relative ${styles.boxAdjust}`}
                      >
                        <div
                          className={`is-flex ${styles.modal_body_type_label}`}
                        >
                          <img
                            src={coupe}
                            alt="クーペ・オープンカー"
                          />
                          <em
                            className={`is-size-5-desktop is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                          >
                            クーペ
                            <br />
                            オープンカー
                          </em>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* 追加_新検索軸 */}
              <div id="ab-target-selection-202309" className="is-hidden is-margin-bottom-7">
                <h2 className="has-text-weight-semibold is-margin-bottom-3">
                  こだわり条件から探す
                </h2>
                <div className="columns is-1 is-mobile is-multiline is-variable">
                  {CARMO_CONST.SELECTION.ITEMS.map(((item) => (
                    <div className="column is-half is-margin-bottom-3">
                      <Link
                        // eslint-disable-next-line max-len
                        to={`/lineup/selection/${item.id}/`}
                        className={`box is-relative ${styles.selectionButton}`}
                      >
                        <span
                          className={`is-size-5 ${styles.selectionButtonText}`}
                        >
                          {item.benefit.title}
                        </span>
                      </Link>
                    </div>
                  )))}
                </div>
              </div>
              {/* // 追加_新検索軸 */}

              {/*  キーワード検索  */}
              <div className="is-hidden-tablet is-full is-margin-bottom-3">
                <h2 className="has-text-weight-semibold">
                  キーワードから探す
                </h2>
                <FreeWordSearchBox />
                <p className="is-size-6 has-text-danger is-margin-left-1 is-margin-right-1 is-hidden">
                  該当する車種は見つかりませんでした。キーワードを変更して、もう一度お試しください。
                </p>
              </div>
              <div className={`is-padding-top-5 ${styles.usedcarFlow}`}>
                <div className="column is-full">
                  <div className="has-text-right">
                    <a
                      href="/usedcar/#top-search-area"
                    >
                      <p className="is-size-4">
                        <span className="has-text-weight-semibold">中古車</span>
                        を探す
                        <span
                          className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                        />
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default SearchNewcar;
// ${styles.testPt01} ${styles.testPt02} ${styles.testPt03} ${styles.testPt04}
