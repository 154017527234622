/* eslint-disable react/no-danger */
import React, {
  FC, useState, useMemo, useRef,
} from 'react';
import * as styles from './FAQAccordion.module.styl';

interface Props {
  question: string
  answer: string
}

interface State {
  isActive: boolean
}

/**
 * FAQアコーディオン
 */
const FAQAccordion: FC<Props> = ({ question, answer }) => {
  const [state, setState] = useState<State>({
    isActive: false,
  });

  const toggle = (): void => {
    const { isActive } = state;
    setState({
      isActive: !isActive,
    });
  };

  const panelDiv = useRef<HTMLDivElement>(null);
  const panelMaxHeight = useMemo(
    () => (state.isActive ? `${panelDiv.current?.scrollHeight}px` : '0px'),
    [panelDiv, state.isActive],
  );

  return (
    <div className="is-border-line is-size-5 has-background-grey-yellow">
      <a
        onClick={toggle}
        href="#faq"
        className={`is-relative ${styles.accordion}`}
      >
        <div className="columns is-mobile is-relative">
          <div className="column">
            <div className="has-text-black is-size-5">{question}</div>
          </div>
          <div className={`column is-narrow ${styles.iconArea}`}>
            <i
              className={`icon is-small has-background-primary ${
                styles.accordionIcon
              } ${state.isActive ? 'icon-minus' : 'icon-plus'}`}
            />
          </div>
        </div>
      </a>
      <div
        ref={panelDiv}
        className={`${styles.panel}`}
        style={{ maxHeight: panelMaxHeight }}
      >
        <p
          className="is-size-5 is-padding-3"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
    </div>
  );
};

export default FAQAccordion;
