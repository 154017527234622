import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import NEWCAR_DEFINE from '@/newcar/util/define';
import CARMO_CONST from '@/common/util/const';
import toyota from '@/newcar/ui/common/img/maker/toyota.png';
import nissan from '@/newcar/ui/common/img/maker/nissan.png';
import honda from '@/newcar/ui/common/img/maker/honda.png';
import mazda from '@/newcar/ui/common/img/maker/mazda.png';
import subaru from '@/newcar/ui/common/img/maker/subaru.png';
import suzuki from '@/newcar/ui/common/img/maker/suzuki.png';
import daihatsu from '@/newcar/ui/common/img/maker/daihatsu.png';
import mitsubishi from '@/newcar/ui/common/img/maker/mitsubishi.png';

import newcarIconRed from '@/newcar/ui/common/img/object/icon/newcar-icon-red.svg';

import arrowIconWhite from '@/newcar/ui/common/img/object/icon/arrow-icon-white.svg';

import sedan from '@/newcar/ui/common/img/bodytype/black/sedan.svg';
import stationwagon from '@/newcar/ui/common/img/bodytype/black/stationwagon.svg';
import minivan from '@/newcar/ui/common/img/bodytype/black/minivan.svg';
import suv from '@/newcar/ui/common/img/bodytype/black/suv.svg';
import mini from '@/newcar/ui/common/img/bodytype/black/mini.svg';
import compact from '@/newcar/ui/common/img/bodytype/black/compact.svg';
import hatchback from '@/newcar/ui/common/img/bodytype/black/hatchback.svg';
import coupe from '@/newcar/ui/common/img/bodytype/black/coupe.svg';

import FreeWordSearchBoxAB from '@/newcar/ui/top/organism/FreeWordSearchBoxAB';

import * as styles from './SearchNewcarHorizontalArrangement.module.styl';

interface State {
  isMakerModalActive: boolean
  isBodyTypeModalActive: boolean
}

const SearchNewcar: FC = () => {
  const [state, setState] = useState<State>({
    isMakerModalActive: false,
    isBodyTypeModalActive: false,
  });

  const toggleMakerModal = (): void => {
    const { isMakerModalActive } = state;
    setState({
      isBodyTypeModalActive: false,
      isMakerModalActive: !isMakerModalActive,
    });
  };

  return (
    <div className={`column ${styles.newcarSearchArea}`}>
      <div className={`is-hidden-tablet ${styles.semicircle}`} />
      {/*  メーカーから探す  */}
      <div className="is-hidden column is-half-tablet is-padding-2">
        <div className={styles.top_search_button}>
          <a
            onClick={toggleMakerModal}
            href="#modal"
            className="button is-padding-2-desktop has-background-redblack has-text-white show-makerModal"
            data-target="maker-modal"
          >
            <span className="icon-arrow-right is-hidden-desktop" />
            <span className="icon-arrow-right-big is-hidden-touch" />
          </a>
        </div>
      </div>

      <div className="columns is-multiline is-vcentered sp-search-box-positon is-relative">
        <div className="column columns is-margin-wide-0 is-full">
          <div className="column">
            <div className={`is-mobile is-multiline has-background-white radius-mini is-padding-4 ${styles.topSearch}`}>
              <h2 className="has-text-centered is-padding-bottom-4">
                <div className="">
                  <img
                    src={newcarIconRed}
                    alt="車のアイコン"
                    className={`is-hidden-mobile ${styles.newcarIconPositon}`}
                  />
                </div>
                <div className="is-size-3 has-text-primary has-text-weight-semibold">
                  新車カーリースを探す
                </div>
              </h2>
              {/*  */}

              <div className="columns">
                <div className="column">
                  {/* メーカーから探す */}
                  <div className="is-margin-right-5">
                    <div className={`is-margin-bottom-3 ${styles.headingArea}`}>
                      <div className={`${styles.headingIcon}`} />
                      <h2 className="has-text-weight-semibold">
                        メーカーから探す
                      </h2>
                    </div>

                    <div className="columns is-1 is-mobile is-multiline is-variable">
                      <div className="columns is-mobile is-multiline">
                        <div className="column is-one-quarter is-margin-bottom-3">
                          <Link
                            to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.TOYOTA)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img src={toyota} alt="トヨタ" />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_one_text}`}
                              >
                                トヨタ
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter is-margin-bottom-3">
                          <Link
                            to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.NISSAN)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img src={nissan} alt="日産" />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                日産
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter is-margin-bottom-3">
                          <Link
                            to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.HONDA)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img src={honda} alt="ホンダ" />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                ホンダ
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter is-margin-bottom-3">
                          <Link
                            to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.MAZDA)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img src={mazda} alt="マツダ" />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                マツダ
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter">
                          <Link
                            to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.SUBARU)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img src={subaru} alt="スバル" />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_one_text}`}
                              >
                                スバル
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter">
                          <Link
                            to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.SUZUKI)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img src={suzuki} alt="スズキ" />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_one_text}`}
                              >
                                スズキ
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter">
                          <Link
                            to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.DAIHATSU)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img src={daihatsu} alt="ダイハツ" />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                ダイハツ
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter">
                          <Link
                            to={NEWCAR_DEFINE.PATH.MAKER_PATH(CARMO_CONST.MAKER.ID.MITSUBISHI)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img src={mitsubishi} alt="三菱" />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                三菱
                              </em>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  {/* ボディタイプから探す */}
                  <div className="is-margin-bottom-7 ">
                    <div className={`is-margin-bottom-3 ${styles.headingArea}`}>
                      <div className={`${styles.headingIcon}`} />
                      <h2 className="has-text-weight-semibold">
                        車のタイプから探す
                      </h2>
                    </div>
                    <div className="columns is-1 is-mobile is-multiline is-variable">
                      <div className="columns is-mobile is-multiline">
                        <div className="column is-one-quarter is-margin-bottom-3">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                              CARMO_CONST.BODY_TYPE.ID.MINI_CAR,
                            )}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img src={mini} alt="軽自動車" />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_one_text}`}
                              >
                                軽自動車
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter is-margin-bottom-3">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                              CARMO_CONST.BODY_TYPE.ID.COMPACT_CAR,
                            )}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img
                                src={compact}
                                alt="コンパクトカー"
                              />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                コンパクト
                                <br />
                                カー
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter is-margin-bottom-3">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.MINIVAN)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img
                                src={minivan}
                                alt="ミニバン・ワゴン"
                              />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                ミニバン
                                <br />
                                ワゴン
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter is-margin-bottom-3">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.SUV)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img
                                src={suv}
                                alt="SUV・クロカン"
                              />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                SUV
                                <br />
                                クロカン
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.SEDAN)}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img src={sedan} alt="セダン" />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_one_text}`}
                              >
                                セダン
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                              CARMO_CONST.BODY_TYPE.ID.HATCH_BACK,
                            )}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img
                                src={hatchback}
                                alt="ハッチバック"
                              />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_one_text}`}
                              >
                                ハッチバック
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                              CARMO_CONST.BODY_TYPE.ID.STATION_WAGON,
                            )}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img
                                src={stationwagon}
                                alt="ステーションワゴン"
                              />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                ステーションワゴン
                              </em>
                            </div>
                          </Link>
                        </div>
                        <div className="column is-one-quarter">
                          <Link
                            to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(
                              CARMO_CONST.BODY_TYPE.ID.COUPE,
                            )}
                            className={`box is-relative ${styles.boxAdjust}`}
                          >
                            <div
                              className={`is-flex ${styles.modal_body_type_label}`}
                            >
                              <img
                                src={coupe}
                                alt="クーペ・オープンカー"
                              />
                              <em
                                className={`is-size-7 has-text-centered ${styles.modal_body_type_two_text}`}
                              >
                                クーペ
                                <br />
                                オープンカー
                              </em>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  新車一覧  */}
              <div className="columns">
                <div className="column">
                  {/*  キーワード検索  */}
                  <div className="is-full is-margin-right-5">
                    <div className={`is-margin-bottom-3 ${styles.headingArea}`}>
                      <div className={`${styles.headingIcon}`} />
                      <h2 className="has-text-weight-semibold">
                        キーワードから探す
                      </h2>
                    </div>
                    <FreeWordSearchBoxAB />
                    <p className="is-size-6 has-text-danger is-margin-left-1 is-margin-right-1 is-hidden">
                      該当する車種は見つかりませんでした。キーワードを変更して、もう一度お試しください。
                    </p>
                  </div>
                </div>
                <div className="column">
                  <div className="">
                    <div className={`is-margin-bottom-3 ${styles.headingArea}`}>
                      <div className={`${styles.headingIcon}`} />
                      <h2 className="has-text-weight-semibold">
                        新車の一覧から探す
                      </h2>
                    </div>
                    <div className={`is-margin-top-4 ${styles.quick_button}`}>
                      <Link
                        className=""
                        to={NEWCAR_DEFINE.PATH.LINEUP_TOP}
                      >
                        <div className={`button has-background-redblack has-text-centered ${styles.lineupButton}`}>
                          <strong
                            className={`is-size-5-desktop is-size-5-tablet is-size-7-mobile has-text-white ${styles.quickText}`}
                          >
                            新車の一覧
                          </strong>
                          <img
                            src={arrowIconWhite}
                            alt="このタイプで審査申込ボタン"
                            className={styles.icon_quick}
                          />
                        </div>
                      </Link>

                    </div>
                  </div>
                </div>
              </div>
              {/* 中古車テキストver */}
              <div className={`${styles.usedcarFlow}`}>
                <div className="column is-full">
                  <div className="has-text-right">
                    <a
                      href="/usedcar/#top-search-area"
                    >
                      <p className="is-size-5">
                        <span className="has-text-weight-semibold">中古車</span>
                        を探す
                        <span
                          className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
                        />
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default SearchNewcar;
// ${styles.testPt01} ${styles.testPt02} ${styles.testPt03} ${styles.testPt04}
