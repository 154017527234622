// extracted by mini-css-extract-plugin
export const box = "SearchNewcar-module--box--2t0Nc";
export const newcarSearchArea = "SearchNewcar-module--newcar-search-area--3tDZQ";
export const spSearchHeadingPosition = "SearchNewcar-module--sp-search-heading-position--1vxi4";
export const semicircle = "SearchNewcar-module--semicircle--2P0Dg";
export const newcarSearchAreaBg = "SearchNewcar-module--newcar-search-area-bg--2X8QE";
export const newcarIconPositon = "SearchNewcar-module--newcar-icon-positon--1T4fd";
export const newcarHeading = "SearchNewcar-module--newcar-heading--SLgb5";
export const isMarginCarsearch = "SearchNewcar-module--is-margin-carsearch--3PoOd";
export const topSearch = "SearchNewcar-module--top-search--2Xlbe";
export const topSearchFeeRangeWrapper = "SearchNewcar-module--top-search-fee-range-wrapper--lBna4";
export const topSearchFeeRangeTitle = "SearchNewcar-module--top-search-fee-range-title--1dbau";
export const isLarge = "SearchNewcar-module--is-large--3FwWl";
export const topSearchFeeRangeContainer = "SearchNewcar-module--top-search-fee-range-container--18tYw";
export const topSearchFeeRangeButton = "SearchNewcar-module--top-search-fee-range-button--3Kh-R";
export const topSearchFeeRangeLink = "SearchNewcar-module--top-search-fee-range-link--12AhO";
export const top_search_button = "SearchNewcar-module--top_search_button--1nK90";
export const searchText = "SearchNewcar-module--search-text--3qmww";
export const modal_maker_width = "SearchNewcar-module--modal_maker_width--2rjht";
export const modal_maker_nostyle = "SearchNewcar-module--modal_maker_nostyle--2PHIz";
export const modal_maker_input = "SearchNewcar-module--modal_maker_input--2aE3y";
export const modal_maker_items = "SearchNewcar-module--modal_maker_items--25eHg";
export const modal_body_type_label = "SearchNewcar-module--modal_body_type_label--2ejy2";
export const modal_body_type_one_text = "SearchNewcar-module--modal_body_type_one_text--1ot69";
export const modal_body_type_two_text = "SearchNewcar-module--modal_body_type_two_text--1YoXQ";
export const modal_maker_icons = "SearchNewcar-module--modal_maker_icons--3P0mv";
export const boxAdjust = "SearchNewcar-module--box-adjust--1SV8e";
export const top_search_button_ab = "SearchNewcar-module--top_search_button_ab--3MISA";
export const topLinkIconArrow = "SearchNewcar-module--top-link-icon-arrow--3fRRf";
export const top_small_size = "SearchNewcar-module--top_small_size--1xXP-";
export const selectionButton = "SearchNewcar-module--selection-button--1LuLd";
export const selectionButtonText = "SearchNewcar-module--selection-button-text--JpmxL";
export const priceBox = "SearchNewcar-module--priceBox--1mUiC";
export const usedcarFlow = "SearchNewcar-module--usedcar-flow--3EHcj";
export const isTabletFullWidth = "SearchNewcar-module--is-tablet-full-width--2WocP";