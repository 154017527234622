import newcarLogo from '@/newcar/ui/common/img/logo/logo_newcar.svg';
import beginnerIcon from '@/newcar/ui/common/img/object/icon/beginner-icon.svg';
import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import NEWCAR_DEFINE from '@/newcar/util/define';
import * as styles from './Header.module.styl';

interface State {
  isMenuActive: boolean
}

type HeaderProps = {
  h1Text?: string;
}

const Header: FC<HeaderProps> = ({ children, h1Text }) => {
  const [state, setState] = useState<State>({
    isMenuActive: false,
  });

  const toggleMenu = (): void => {
    const { isMenuActive } = state;
    setState({
      isMenuActive: !isMenuActive,
    });
  };

  return (
    <header className="is-full-width no_fixed is-zindex-menu">
      <nav
        className={`${styles.container} container navbar is-zindex-menu`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/" className="is-flex has_vertical_centered">
              <div className="">
                <img
                  className="is-full-width"
                  src={newcarLogo}
                  alt={h1Text || 'おトクにマイカー 定額カルモくん'}
                />
              </div>
            </Link>
          </div>
          <div className="is-flex beginner-icon is-hidden-desktop">
            <Link to="/docs/first-guide/">
              <img
                src={beginnerIcon}
                alt="はじめての方へ"
                className="is-vertical-align-middel"
              />
            </Link>
          </div>
          <div
            onClick={toggleMenu}
            role="button"
            className={`navbar-burger burger navbtn ${
              state.isMenuActive ? 'is-active' : ''
            }`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarManus"
            tabIndex={0}
            id="nvbtn"
            data-gtm="gtm-newcar-navbar"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </div>
        </div>

        <div className={`${styles.beginnerIcon} beginner-icon is-flex is-hidden-touch`}>
          <Link to={NEWCAR_DEFINE.PATH.FIRST_GUIDE}>
            <img
              src={beginnerIcon}
              alt="はじめての方へ"
              className="is-vertical-align-middel"
            />
          </Link>
          <p className="popup">はじめての方へ</p>
        </div>

        <div
          id="navbarManus"
          className={`navbar-menu ${state.isMenuActive ? 'is-active' : ''}`}
        >
          <div className="navbar-start" />
          {/* PCメニュー */}
          <div className="navbar-end">
            <div className={`${styles.menu_desktop} navbar-item is-hoverable has-dropdown has-text-black-ter`}>
              <a className={`${styles.menu_item_link} navbar-item`} href="/simulation">
                シミュレーション結果
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/docs/about">
                カーリースとは
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/docs/contract">
                ご納車までの流れ
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/docs/maintenance">
                メンテナンスプラン
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/campaigns">
                キャンペーン一覧
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/docs/faq">
                よくあるご質問
              </a>
              <a className={`${styles.menu_item_link} navbar-item`} href="/contact">
                お問合せ
              </a>
            </div>
            {/* SPメニューテスト */}
            <div className="navbar-item is-hoverable has-dropdown has-text-black-ter is-padding-left-5 is-padding-right-5">
              <p
                className={`${styles.navbarSubTitle} is-size-4 has-text-weight-semibold is-margin-top-3 is-margin-bottom-3`}
              >
                クルマをさがす
              </p>
              <Link className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`} to={NEWCAR_DEFINE.PATH.NEWCAR_LINEUP} data-gtm="gtm-newcar-navbar-newcar-search">
                新車をさがす
                <span className={styles.arrow_icon} />
              </Link>
              <Link className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`} to={NEWCAR_DEFINE.PATH.USEDCAR_TOP} data-gtm="gtm-newcar-navbar-usedcar-search">
                中古車をさがす
                <span className={styles.arrow_icon} />
              </Link>
              <p
                className={`${styles.navbarSubTitle} is-size-4 is-margin-top-5 has-text-weight-semibold is-margin-bottom-3`}
              >
                定額カルモくんって？
              </p>
              <Link className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`} to={NEWCAR_DEFINE.PATH.FIRST_GUIDE} data-gtm="gtm-newcar-navbar-first-guide">
                はじめての方へ
                <span className={styles.arrow_icon} />
              </Link>
              <Link className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`} to={NEWCAR_DEFINE.PATH.ABOUT_CARLEASE} data-gtm="gtm-newcar-navbar-carlease-lineup">
                カーリースとは？
                <span className={styles.arrow_icon} />
              </Link>
              <Link className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`} to={NEWCAR_DEFINE.PATH.CONTRACT_FLOW} data-gtm="gtm-newcar-navbar-docs-contract">
                ご納車までの流れ
                <span className={styles.arrow_icon} />
              </Link>
              <Link
                className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`}
                to={NEWCAR_DEFINE.PATH.MAINTENANCE}
                data-gtm="gtm-newcar-navbar-docs-maintenance"
              >
                メンテナンスプラン
                <span className={styles.arrow_icon} />
              </Link>
              <Link
                className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`}
                to={NEWCAR_DEFINE.PATH.MORAERU}
                data-gtm="gtm-newcar-navbar-moraeru"
              >
                もらえるプラン
                <span className={styles.arrow_icon} />
              </Link>
              <Link
                className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`}
                to={NEWCAR_DEFINE.PATH.USERVOICE}
                data-gtm="gtm-newcar-navbar-uservoice"
              >
                お客様の声
                <span className={styles.arrow_icon} />
              </Link>
              <hr className={styles.nav_border} />
              <Link className={`${styles.menu_item_link} ${styles.nav_borde} is-padding-left-3 navbar-item is-size-4`} to={NEWCAR_DEFINE.PATH.CAMPAIGNS} data-gtm="gtm-newcar-navbar-campaigns">
                キャンペーン一覧
                <span className={styles.arrow_icon} />
              </Link>
              <Link
                className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`}
                to={NEWCAR_DEFINE.PATH.MAGAZINE}
                data-gtm="gtm-newcar-navbar-mag"
              >
                カルモマガジン
                <span className={styles.arrow_icon} />
              </Link>
              <Link
                className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`}
                to={NEWCAR_DEFINE.PATH.FAQ}
                data-gtm="gtm-newcar-navbar-faq"
              >
                よくあるご質問
                <span className={styles.arrow_icon} />
              </Link>
              <Link
                className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`}
                to={NEWCAR_DEFINE.PATH.CONTACT}
                data-gtm="gtm-newcar-navbar-contact"
              >
                お客様お問い合わせ窓口
                <span className={styles.arrow_icon} />
              </Link>
              <Link className={`${styles.menu_item_link} is-padding-left-3 navbar-item is-size-4`} to={NEWCAR_DEFINE.PATH.SIMULATION_ROUTING} data-gtm="gtm-newcar-navbar-simulation">
                シミュレーション結果
                <span className={styles.arrow_icon} />
              </Link>
            </div>
          </div>
        </div>
      </nav>
      {children}
    </header>
  );
};

export default Header;
