import { useStaticQuery, graphql } from 'gatsby';

const useNewCarTopQuery = (): GatsbyTypes.newCarTopQuery => useStaticQuery<GatsbyTypes.newCarTopQuery>(graphql`
    query newCarTop {
      microcmsNewcarTop {
        id
        faq {
          answer
          question
        }
        userVoices {
          id
          initial
          prefecture
          age
          maker
          carModel
          ratingOverall
          ratingCS
          ratingCar
          ratingWebsite
          comment
          postedAt
        }
      }
    }
  `);

export default useNewCarTopQuery;
