import React from 'react';
import * as styles from '@/newcar/ui/top/template/index.module.styl';

interface DifferencePoints {
  id: number;
  title: string | JSX.Element;
  htmlContent: string | JSX.Element;
  addOrderClass: string;
  notice?: string;
}

export const DifferencePointList: ReadonlyArray<Readonly<DifferencePoints>> = [
  {
    id: 1,
    title: (
      <>
        業界最安水準
        <span className="is-size-6">※</span>
        、
        <br className={styles.isShowDesktop} />
        月1万円台〜
      </>
    ),
    htmlContent: (
      <>
        <p>
          定額カルモくんは、業界最安水準※の価格で新車を月1万円台から持てます。
          頭金などの初期費用や、ボーナス払いは不要。
          月額定額で新車にお乗りいただけます。
          <span className="is-first-notice">
            ※）文末の制作日における調査結果に基づく。
            <br />
            調査概要はコンテンツポリシー参照。
          </span>
        </p>
        <p>
          月額料金には自動車税や重量税、
          自賠責保険料といった法定費用が
          コミコミなのもカーリースの特徴です。
          購入の場合、毎年自動車税を払う必要がありますが、
          カーリースでは月額料金に含まれているのでわざわざ準備して払う必要はありません。
          さらに、定額カルモくんのメンテナンスプランにご加入いただくと車検代も月額化でき、方々の支払いが一本化できるので、家計の管理がもっとラクになります。
        </p>
        <div className="difference-button-first-container has-text-centered">
          <a
            className={`button radius-most is-large is-fixed-width-button ${styles.differenceAccordionButtonFirst}`}
            href="/column/newcar/reason-for-cheap/"
          >
            安さの仕組みは？
            <span
              className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
            />
          </a>
          <a
            className={`button radius-most is-large is-fixed-width-button ${styles.differenceAccordionButtonSecond}`}
            href="/column/newcar/lease-or-buy/"
          >
            車は購入かカーリースか？
            <span
              className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
            />
          </a>
        </div>
      </>
    ),
    addOrderClass: 'is-first',
  },
  {
    id: 2,
    title: (
      <>
        追加料金なしで
        <br className={styles.isShowDesktop} />
        走り放題！
      </>
    ),
    htmlContent: (
      <>
        <p>
          定額カルモくんは、7年以上のご契約なら走行距離の制限はありませんので、
          通勤通学など日常的に使われるお客様も安心してご利用いただけます。また、
          もらえるオプションにご加入いただくと契約満了時に返却しなくてよいので車検に通る範囲のカスタマイズもペットと一緒にドライブもOK！
        </p>
        <p>
          お車はすべて正規ディーラーから納車される新車です。
          カラーやグレード、メーカーオプションもディーラーオプションも
          ご予算に合わせて自由に組み合わせいてただけますのでお客様だけの特別なマイカーとしてカーライフをお楽しみいただけます。
        </p>
        <div className="has-text-centered">
          <a
            className="button radius-most is-large is-fixed-width-button is-margin-top-7"
            href="/column/newcar/how-to-choose/"
          >
            カーリースの選び方とは？
            <span
              className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
            />
          </a>
        </div>
      </>
    ),
    addOrderClass: 'is-second',
    notice: '※7年以上のご契約の場合。',
  },
  {
    id: 3,
    title: (
      <>
        納車まで
        <br className={styles.isShowDesktop} />
        自宅で完結！
      </>
    ),
    htmlContent: (
      <>
        <p>
          定額カルモくんは24時間ネットでおクルマ選びからお申し込みまで完了いただけます。
          忙しくて店舗に行けない方や、店舗が家から遠い方なども自分のペースで進められるのでタイパも抜群！
        </p>
        <p>
          また、契約手続きも郵送で完結。担当ディーラーからご自宅まで納車いたしますので、
          自宅にいながらカンタンにカーライフが始められます！
        </p>
      </>
    ),
    addOrderClass: 'is-third',
  },
  {
    id: 4,
    title: (
      <>
        ご契約後も
        <br className={styles.isShowDesktop} />
        しっかりサポート！
      </>
    ),
    htmlContent: (
      <>
        <p>
          定額カルモくんでは、ご契約後のお客様のカーライフをサポートする専任のスタッフを設置しております。
          車検・法定点検時期のお声がけや、カーライフを充実させるお役立ち情報・ご契約者限定の特典の発信なども積極的に行っております。
        </p>
        <p>
          また、ご契約後にご提供する手引き書、アプリでも各種情報をご確認いただけます。
          カーライフに関するご相談や、ご利用期間中のメンテナンスプランの使い方など、
          お困りのことやご不安な点がございましたらLINE、メール、電話でご相談を承っておりますのでお気軽にお問合せください。
        </p>
      </>
    ),
    addOrderClass: 'is-fourth',
  },
  {
    id: 5,
    title: (
      <>
        未使用車や
        <br className={styles.isShowDesktop} />
        中古車も選べる！
      </>
    ),
    htmlContent: (
      <>
        <p>
          定額カルモくんの中古車リースは常時、全国数千台をご用意。
          プロの査定士が厳選して仕入れ第三者機関による鑑定書付の「高品質・低価格・短納期」なカルモ認定中古車も取り揃えておりますので、お客様のご条件にあった１台がきっと見つかります。
        </p>
        <p>
          また、サイト掲載車両は全て車検が2年、修理費上限なしの故障保証1年付き※なので安心してお乗りいただけます。
          中古車は新車に比べ納車期間も短いのでお急ぎの方も、そうでない方もお気軽にご相談ください。
        </p>
        <div className="has-text-centered">
          <a
            className="button radius-most is-large is-fixed-width-button is-margin-top-7"
            href="/usedcar/lineup/"
          >
            中古車一覧をみる
            <span
              className={`is-small icon-arrow has-background-black ${styles.topLinkIconArrow} ${styles.top_small_size}`}
            />
          </a>
        </div>
      </>
    ),
    addOrderClass: 'is-fifth',
  },
];
