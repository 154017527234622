import React, {
  Component, ReactNode, FormEventHandler,
} from 'react';
import * as queryString from 'query-string';
import * as styles from './FreeWordSearchBox.module.styl';

interface Props {
  word?: string
}

interface State {
  word: string
}

/**
 * フリーワード検索ボックス
 */
export default class FreeWordSearchBox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      word: props.word ?? '',
    };
  }

  onSubmit: FormEventHandler = (e): void => {
    e.preventDefault();

    const {
      word,
    } = this.state;

    if (word) {
      const query = queryString.stringify({
        word,
      });
      window.location.href = `/lineup/list?${query}`;
    } else {
      window.location.href = '/lineup';
    }
  };

  render(): ReactNode {
    const {
      word: text,
    } = this.state;
    return (
      <form
        onSubmit={this.onSubmit}
        className={`columns is-mobile is-margin-0 is-padding-2 form-transition ${styles.searchFreewordInput}`}
      >
        <div className="column is-padding-wide-0">
          <label htmlFor="carname">
            <input
              type="text"
              value={text}
              onChange={(e): void => this.setState({ word: e.target.value })}
              placeholder="例：トヨタ ミニバン ヤリス"
              className="input is-size-4-desktop is-size-5-touch is-rounded cartname"
              name="searchword"
            />
          </label>
        </div>
        <div
          className={`column is-narrow is-padding-wide-0 ${styles.searchFreewordButton}`}
        >
          <button className="button is-black" type="submit">
            <i className="icon icon-search">
              <div />
            </i>
          </button>
        </div>
      </form>
    );
  }
}
