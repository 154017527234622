import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const StructuredFAQ: FC = () => {
  const JsonLDFAQ = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [{
      '@type': 'Question',
      name: '走行距離に制限はありますか',
      acceptedAnswer: {
        '@type': 'Answer',
        text: "7年以上でご契約される場合、走行距離に制限はございません。また、契約満了時に乗っていた車を追加料金無しでもらうことが可能になる「もらえるオプション」もご用意しており、カーリースでもマイカー感覚でご利用いただけます。詳しくは<a href='https://carmo-kun.jp/docs/moraeru/'>こちら</a>をご覧ください。",
      },
    }, {
      '@type': 'Question',
      name: 'リース料金には何が含まれていますか',
      acceptedAnswer: {
        '@type': 'Answer',
        text: "車両代に加えて自動車税や重量税、自賠責保険などの法定費用などがリース料金に含まれています。加えて、車検点検費用もカバーする<a href='https://carmo-kun.jp/docs/maintenance/'>メンテナンスプラン</a>もオプションで追加出来ます。自動車購入の際には自己負担になる部分が含まれているため、車に関する出費がフラットになり家計の管理がラクになります。ぜひご検討くださいませ。",
      },
    }, {
      '@type': 'Question',
      name: '新車が月額1万円台ってホント？',
      acceptedAnswer: {
        '@type': 'Answer',
        text: "本当です。<a href='https://carmo-kun.jp/lineup/?order=popularity'>シミュレーションページ</a>で算出いただける金額以外の請求はございません。頭金0円、ボーナス払い0円、満了時の残価精算も0円で安心のカーリースです。とにかく新車に安く乗るなら業界最安水準の定額カルモくんをご検討ください。",
      },
    }],
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {
          JSON.stringify(JsonLDFAQ)
        }
      </script>
    </Helmet>
  );
};

export default StructuredFAQ;
