import React, { FC } from 'react';
import SEOBase from '@/newcar/ui/common/organism/SEO';

const TITLE = '新車・中古車カーリース(車のサブスク)なら 定額カルモくん ';
const DESCRIPTION = '【業界最安水準】頭金・ボーナス払い0円で新車に格安で乗れる。毎月定額、税金コミコミで安心「おトクにマイカー 定額カルモくん」は新車カーリース（車のサブスク）サービスです。メンテナンスも月額定額にできるプラン、契約満了後に車がもらえるプランもご用意。';

const SEO: FC = () => (
  <SEOBase
    title={TITLE}
    description={DESCRIPTION}
    ogTitle={TITLE}
    ogDescription={DESCRIPTION}
    twitterTitle={TITLE}
    twitterDescription={DESCRIPTION}
  />
);

export default SEO;
